import $ from "jquery";

import Swiper from 'swiper/bundle';

$(function() {
    $(".bl-hgallery").each(function() {
        const $bl = $(this);
        initHGallery($bl);
    });
});

function initHGallery($bl) {
    const swiper = new Swiper($bl.find('.swiper').get(0), {
      slidesPerView: "auto",
      //rewind: true,
      //autoHeight: true,
      scrollbar: {
        el: $bl.find(".swiper-scrollbar").get(0),
        draggable: true
      },
      navigation: {
        prevEl: $bl.find(".btnprev").get(0),
        nextEl: $bl.find(".btnnext").get(0)
      }
    });
}
